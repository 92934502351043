import * as FullStory from "@fullstory/browser";
import { cssBundleHref } from "@remix-run/css-bundle";
import "~/tailwind.application.css";

import type { MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import stylesheet from "~/tailwind.application.css?url";
import "~/tailwind.application.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FeatureFlagsProvider } from "~/FeatureFlagsContext";
import { GlobalLoading } from "~/components/GlobalLoading";
import { SupabaseClientProvider } from "~/contexts/SupabaseClientContext/SupabaseClientContext";
import {
  SupabaseSessionProvider,
  useSupabaseSession,
} from "~/contexts/SupabaseSessionContext";
import { TrpcClientProvider } from "~/utils/TrpcClientProvider";
import { trpc } from "~/utils/trpc";

import "vanilla-cookieconsent/dist/cookieconsent.css";

import type { User } from "@supabase/supabase-js";
import { setupCookieConsent } from "~/setupCookieConsent";

import "react-toastify/dist/ReactToastify.css";

import { json } from "@remix-run/node";
import { Effect } from "effect";
import _ from "lodash";
import { RemixArgsContext } from "~/application/contexts/RemixArgsContext";
import { UserContext } from "~/application/contexts/UserContext";
import { ResponseService } from "~/application/services/ResponseService";
import { User as UserService } from "~/application/services/User";
import { UnknownErrorComponent } from "~/components/UnknownErrorComponent";
import { effectLoader } from "~/remix-effect/effectLoader.server";

import { type IStaticMethods } from "preline/preline";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

if (typeof window !== "undefined") {
  import("preline/preline");
}

export const ErrorBoundary = () => {
  return (
    <html className="w-full h-full">
      <head>
        <title>Switchgrid - Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body className="w-full h-full">
        <UnknownErrorComponent />
        <Scripts />
      </body>
    </html>
  );
};

export const loader = effectLoader(() =>
  Effect.gen(function* ($) {
    const {
      context: { session, origin },
    } = yield* RemixArgsContext;
    const { headers } = yield* ResponseService;
    const { getFeatureFlags } = yield* $(
      Effect.promise(() => import("../application/useCases/getFeatureFlags"))
    );
    const flags = yield* getFeatureFlags.useCase({});
    const user = yield* UserService.getOrNull();
    const hasValidServiceAccount = (yield* UserContext.serviceAccount) !== null;
    return json(
      {
        session,
        flags,
        origin,
        user,
        hasValidServiceAccount,
        ENV: {
          ..._.pickBy(process.env, (value, key) => key.startsWith("PUBLIC_")),
        },
      },
      {
        headers,
      }
    );
  })
);

export const links = () => {
  const isRunningWithVite = process.env.NODE_ENV === "development"; // TODO find a better way to detect vite
  return isRunningWithVite
    ? []
    : [
        ...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
        { rel: "stylesheet", href: stylesheet },
      ];
};

const initializeFullStory = (user: User | undefined) => {
  console.log("initializeFullStory", user);
  !FullStory.isInitialized() && FullStory.init({ orgId: "o-1R7YT6-na1" });
  user &&
    FullStory.identify(user.id, {
      ...user,
    });
};

function App({
  publicEnvironmentVariables,
}: {
  publicEnvironmentVariables: { [key: string]: string | undefined };
}) {
  const htmlRef = useRef<HTMLHtmlElement>(null);

  useEffect(() => {
    if (htmlRef.current) {
      htmlRef.current.classList.add("hydrated");
    }
  }, []);

  const rootData = useLoaderData<typeof loader>();

  const { user, hasValidServiceAccount } = rootData;

  const { session } = useSupabaseSession();

  useEffect(() => {
    const hostname = location.hostname;
    setupCookieConsent({
      hideFromBots: hasValidServiceAccount || !hostname.includes("localhost"), // When running with Cypress, we don't want to hide the banner.
      ifAnalyticsAccepted: () => {
        console.log({ user: session?.user });
        initializeFullStory(session?.user);
      },
    });
  }, [session, hasValidServiceAccount]);

  const [isDarkMode, setDarkMode] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setDarkMode(
      window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    );
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        setDarkMode(event.matches ? true : false);
      });
  }, []);

  return (
    <html
      lang="en"
      ref={htmlRef}
      className={`h-full ${
        isDarkMode && pathname.startsWith("/admin/") ? "dark" : ""
      }`}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,minimum-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="bg-background dark:bg-dark-background h-full flex flex-col">
        <ScrollRestoration />
        <Scripts />
        <ToastContainer
          position="top-right"
          autoClose={5_000}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnHover
          closeOnClick
        />
        {user?.isDemoUser && (
          <div className="w-full text-center bg-green-600 text-white text-xs sticky top-0">
            UTILISATEUR DEMO
          </div>
        )}
        <GlobalLoading />
        <Outlet />
        <div id="modal" />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(
              publicEnvironmentVariables
            )}`,
          }}
        />
      </body>
    </html>
  );
}

export const meta: MetaFunction = () => {
  return [
    { title: "Switchgrid" },
    {
      name: "description",
      content: "API pour accéder aux données de compteur électrique",
    },
  ];
};

export default function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <SupabaseClientProvider
      localDevSupabaseAuthUrl={`${data.origin}/supabaseAuth`}
    >
      <SupabaseSessionProvider>
        <TrpcClientProvider baseApiUrl={`${data.origin}/api`} trpc={trpc}>
          <FeatureFlagsProvider>
            <App publicEnvironmentVariables={data.ENV} />
          </FeatureFlagsProvider>
        </TrpcClientProvider>
      </SupabaseSessionProvider>
    </SupabaseClientProvider>
  );
}
